<template>
  <div v-if="currentRoute" class="buendnisse-partner">
    <stage :stageLabel="localizedHeadline" :big="true" :title="currentRoute.title.rendered"/>
    <introduction fontWeight="bold" :subline="localizedHeadline" :maintext="currentRoute.acf.buendnisse_text"/>
    <div class="buendnisse-partner__content">
        <team-blocks :small="true" :team="currentRoute.acf.buendnisse"/>
        <div class="buendnisse-partner__section-bg">
            <h3 class="buendnisse-partner__sectionhead">{{currentRoute.acf.more}}</h3>
            <inline-blocks :withMargin="false" :blocks="currentRoute.acf.blocks"/>
        </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import TeamBlocks from '@/components/team-blocks.vue'
import InlineBlocks from '@/components/inline-blocks.vue'
import {mapGetters, mapActions} from 'vuex'


export default {
  name: 'Buendnisse-Partner',
  components: {
    Stage,
    Introduction,
    TeamBlocks,
    InlineBlocks
  },
  computed: {
    ...mapGetters([
        'getFetched'
    ]),
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.name];
      return getFetched;
    },
    localizedHeadline() {
      return this.$i18n.locale === 'de' ? 'Bündnisse & Partner' : 'Alliances & Partners'
    }
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.name);
      }
  },
  methods: {
    ...mapActions([
          'fetchCurrentPage',
    ]),
  }
}
</script>
<style lang="scss" scoped>
.buendnisse-partner {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;

    &::v-deep .team-blocks__image {
      object-fit: contain;
      padding: 1rem;
      background-color: #ffffff;
    }

  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin-bottom: 65px;
  }
  &__section-bg {
        padding:30px 0;
        background-color: #e8edf8;
        position: relative;
        margin-bottom: 1.6rem;
        &:after {
            content: '';
            position: absolute;
            width: 100vw;
            left: calc(50% - 50vw);
            top: 0;
            z-index: -1;
            height: 100%;
            background-color: #e8edf8;
        }
    }
}
</style>
